<template>
  <div class="page1">
    <EForm
            :formColumns="formColumns"
            :rowSize="4"
            :optionsBtn="true"
            :actionBtn="false"
            :formData="searchForm"
            ref="form"
            @onSearch="onSearch"
            :searchFlag="true"
            :exportShow="false"
    >
    </EForm>
    <!--    <el-button type="primary" @click="dialogVisible = true" size="small">增加</el-button>-->
    <!--     <EButton type="primary" @click="dialogVisible = true">增加</EButton>-->
    <ETable
            :tableTitle="tableTitle"
            :tableData="tableData"
            :needPagination="true"
            :count="count"
            @changePage="changePage"
            @changeSize="changeSize"
            :page="searchForm.current"
            :page_size="searchForm.size"
    >
      <div slot="status" slot-scope="scope">
        <!--        1未进场 2已进场 3已删除 4已离场-->
        <span v-if="scope.data.status==1">
              未进场
          </span>
        <span  v-if="scope.data.status==2">
            已进场
          </span>
        <span  v-if="scope.data.status==3">
            已删除
          </span>
        <span  v-if="scope.data.status==4">
            已离场
          </span>
      </div>
    </ETable>
  </div>
</template>

<script>
  import ETable from '@/components/ETable';
  import Http from '@/service/http';
  import EDialog from '@/components/EDialog';
  import EButton from '@/components/EButton';
  import EForm from '@/components/EForm';
  import { identity_type, status } from '@/assets/js/config';
  import vxRule from '@/assets/js/formValidate';
  import { otherMixin } from '@/components/mixin';
  import {mapGetters} from 'vuex'
  export default {
    name: 'shopInfo',
    mixins: [otherMixin],
    data() {
      return {
        tableTitle: [
          {
            label: '车牌号',
            prop: 'carNum',
          },
          {
            label: '订单号',
            prop: 'orderId',
          },
          {
            label: '车辆类型',
            prop: 'typeCn',
          },
          {
            label: '收费金额',
            prop: 'outFee',
          },
          {
            label: '收费方式',
            prop: 'feeWayCn',
          },
          {
            label: '收费时间',
            prop: 'outFeeTime',
          },
          {
            label: '出门',
            prop: 'outDoorNum',
          },
          {
            label: '出门车道',
            prop: 'outLane',
          },
          {
            label: '收费车型',
            prop: 'feeCarType',
          },
          {
            label: '进场目的',
            prop: 'typeCn',
          },

        ],
        formColumns: [
          {
            title: '车牌号',
            type: 'text',
            property: 'carNum',
            show: true,
          },
          {
            title: '收费车型',
            type: 'select',
            property: 'feeCarType',
            show: true,
            options: [{
              label: '全部',
              value: '',
            }],
          },
          {
            title: '出门',
            type: 'select',
            property: 'outDoorNum',
            show: true,
            options: [{
              label: '全部',
              value: '',
            }],
          },
          {
            title: '进场目的',
            type: 'select',
            property: 'type',
            show: true,
            options: [
              {
                label: '全部',
                value: '',
              },
              {
                label: '卖货',
                value: 1,
              },
              {
                label: '买货',
                value: 2,
              },
              {
                label: '轿车进场',
                value: 3,
              },
            ],
          },
          {
            title: '收费起始时间',
            type: 'datePicker',
            property: 'payTimeStart',
            show: true,
          },
          {
            title: '收费截止时间',
            type: 'datePicker',
            property: 'payTimeEnd',
            show: true,
          },
          {
            title: '出门车道',
            type: 'select',
            property: 'outLane',
            show: true,
            options: [{
              label: '全部',
              value: '',
            }],
          },

        ],
        tableData: [],
        searchForm: {
          carNum: null,
          feeCarType: null,
          status: null,
          outLane: null,
          payTimeEnd:null,
          payTimeStart:null,
          outDoorNum:null,
          current: 1,
          size: 10,
        },
        count: null,
        exportData: {},

      };
    },
    watch: {},
    created() {
      this.getData();
      this.getCarLabel()
      this.getLaneLabel()
      this.getDoorLabel()
    },
    components: { ETable, EDialog, EButton, EForm },
    computed:{
      ...mapGetters(['buttonList']),
    },
    beforeMount() {},
    methods: {


      onSearch() {
        this.searchForm.current = 1;
        this.getData();
      },
      changePage(current) {
        this.searchForm.current = current;
        this.getData();
      },
      changeSize(size) {
        this.searchForm.size = size;
        this.getData();
      },

      async getData() {

        let res = await Http.outPayList(this.searchForm);
        if (res.code == 200) {
          this.tableData = res.data.records;
          this.count = res.data.total;
        }
      },
      //收费车型
      async getCarLabel() {
        let res = await Http.getCarLabel();
        if (res.code == 200) {
          this.formColumns[1].options = res.data
        }
      },
      //进门车道
      async getLaneLabel() {
        let res = await Http.getLaneLabel();
        if (res.code == 200) {
          this.formColumns[6].options = res.data
        }
      },
      //进门车道
      async getDoorLabel() {
        let res = await Http.getDoorLabel();
        if (res.code == 200) {
          this.formColumns[2].options = res.data
        }
      },
    }
  };
</script>

<style lang="scss" scoped>
  .ws {
    width: 100%;
  }
</style>
